import {
    useStateHeaders
} from '../private/state'
import type { Headers } from '../types/module'
import type { H3Event } from 'h3'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { warnLog } from '../utils/logging'
import { type HTTPHeaderName } from 'h3'

type RequestInterceptorControllerMethodOptions = {
    /**
     * Whether the set value should use the cookie value from the request event (if available)
     * when the provided value is `null` or `undefined`.
     * @default false
     */
    fallbackToForwarding: boolean
}

type RequestAuthorizationController = {
    /**
     * Set a cookie on the request to be made.
     * This method should be used on the server to forward client cookies or
     * a newly refreshed auth token (available on the event context object when making the
     * request during SSR) with the request.
     *
     * To make it easier to forward the specified cookie from the client, it is recommended
     * to set the `fallbackToForwarding` option to `true`. This will use the cookie value
     * from the request event when the provided value is `null` or `undefined`.
     *
     * @example The most common use case
     * controller.setCookie('auth-token', data?.event?.context.newAuthToken, { fallbackToForwarding: true })
     * // This will try to use the `newAuthToken` value from the event context on the server and if it is
     * // not available, it will fall back to using the value from the cookie sent from the client
     * @param name
     * @param value
     * @param options
     */
    setRequestCookie: (name: string, value: MaybeRefOrGetter<string | null | undefined>, options?: Partial<RequestInterceptorControllerMethodOptions>) => void
    /**
     * Set a header on the request to be made.
     *
     * @example Usage in a purely `Bearer` token based client-side auth system
     * controller.setHeader('Authorization', () => `Bearer ${authToken.value}`)
     * @param name
     * @param value
     * @param options
     */
    setRequestHeader: (name: 'Authorization', value: MaybeRefOrGetter<`Bearer ${string}`>) => void
    /**
     * Get the value of a cookie on the client-side.
     * Always returns `null` on the server-side.
     * @param name
     */
    getClientCookie: (name: string) => string | null
}

type AuthorizationInterceptor = ((controller: RequestAuthorizationController, data: {
    event: H3Event | undefined
    willCookiesBeSentFromClient: boolean
}) => void)

let authorizationInterceptor: AuthorizationInterceptor | null = null

export function getAuthorizationInterceptor() {
    return authorizationInterceptor
}

type RequestHeadersController = {
    /**
     * Set a header to be used in the request.
     * @param header the name of the header
     * @param value the value of the header
     */
    setRequestHeader: (header: HTTPHeaderName, value: MaybeRefOrGetter<string | null | undefined>, options?: Partial<RequestInterceptorControllerMethodOptions>) => void
}

type RequestHeadersInterceptor = ((controller: RequestHeadersController, data: {
    event: H3Event | undefined
}) => void)

let requestHeadersInterceptor: RequestHeadersInterceptor | null = null

export function getHeadersFromRequestHeadersInterceptor(data: Parameters<RequestHeadersInterceptor>[1]) {
    const headers: Record<string, string> = {}
    if (!requestHeadersInterceptor) return headers

    requestHeadersInterceptor({
        setRequestHeader: (header, value, options) => {
            const valueToSet = toValue(value) ?? (options?.fallbackToForwarding ? data.event?.headers.get(header as string) : undefined)
            if (!valueToSet) return
            headers[header] = valueToSet
        },
    }, data)

    return headers
}


type RefreshSessionInterceptor = ((data: {
    event: H3Event | undefined
    willCookiesBeSentFromClient: boolean
}) => Promise<void>)

let refreshSessionInterceptor: RefreshSessionInterceptor | null = null

export function getRefreshSessionInterceptor() {
    return refreshSessionInterceptor
}

/**
 * A composable used to configure the composable api module.
 */
export function useComposableApi() {
    const headers = useStateHeaders()

    /**
     * Set a header to be used in all network requests.
     * @param header the name of the header
     * @param value the value of the header
     */
    function setHeader<K extends keyof Headers>(header: K, value: Headers[K] | null) {
        headers.value[header] = value as never
    }

    /**
     * Get the value of a header that is set to be used in all network requests.
     * @param header the name of the header
     */
    function getHeader<K extends keyof Headers>(header: K): Headers[K] | null {
        return headers.value[header] ?? null
    }

    /**
     * Set the interceptor to use when making network requests. This will be called before
     * each request is made.
     * @param interceptor
     */
    function setAuthorizationInterceptor(interceptor: AuthorizationInterceptor) {
        if (import.meta.dev && !import.meta.server && authorizationInterceptor) {
            // on the server, the interceptor is reassigned during each request
            warnLog('[setAuthorizationInterceptor]: Interceptor is being set more than once. Only the first interceptor will be used.')
        }
        authorizationInterceptor = interceptor
    }

    /**
     * Set the interceptor to use when setting headers. This will be called before
     * each request is made.
     * @param interceptor
     */
    function setRequestHeadersInterceptor(interceptor: RequestHeadersInterceptor) {
        if (import.meta.dev && !import.meta.server && requestHeadersInterceptor) {
            // on the server, the interceptor is reassigned during each request
            warnLog('[setRequestHeadersInterceptor]: Interceptor is being set more than once. Only the first interceptor will be used.')
        }
        requestHeadersInterceptor = interceptor
    }

    /**
     * Set the interceptor to use when refreshing the session. This will be called
     * when the API Service detects that the session needs to be refreshed.
     * @param interceptor
     */
    function setRefreshSessionInterceptor(interceptor: RefreshSessionInterceptor) {
        if (import.meta.dev && !import.meta.server && refreshSessionInterceptor) {
            // on the server, the interceptor is reassigned during each request
            warnLog('[setRefreshSessionInterceptor]: Interceptor is being set more than once. Only the first interceptor will be used.')
        }
        refreshSessionInterceptor = interceptor
    }

    return {
        setHeader,
        getHeader,
        setAuthorizationInterceptor,
        setRequestHeadersInterceptor,
        setRefreshSessionInterceptor,
    }
}
